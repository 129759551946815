import { styled } from '@mui/material/styles';

import { getBetterGradesStudents } from 'my-core/landing-data';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const PREFIX = 'GetBetterGrades';
const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  percent: `${PREFIX}-percent`,
  avatarWrapper: `${PREFIX}-avatarWrapper`,
};
const Root = styled('div', { shouldForwardProp: p => p !== 'compressed' })(
  ({ compressed, theme: { breakpoints, constants, palette, spacing } }) => ({
    backgroundColor: palette.background.primary,
    padding: spacing(compressed ? 2 : 5, 0),

    [`& .${classes.container}`]: {
      padding: spacing(0, 2),
      maxWidth: constants.WRAPPER_MAX_WIDTH_LG + parseInt(spacing(10)),
      width: '100%',
      margin: '0 auto',
    },

    [`& .${classes.header}`]: {
      '& > div': {
        display: 'flex',
        flexDirection: 'column',

        '& > :last-child': {
          textTransform: 'capitalize',
        },
      },
    },

    [breakpoints.only('xs')]: {
      textAlign: 'center',

      [`& .${classes.header} > div`]: {
        marginTop: spacing(compressed ? 1 : 2),
      },
    },
    [breakpoints.up('sm')]: {
      padding: spacing(compressed ? 5 : 10, 0),

      [`& .${classes.container}`]: {
        padding: spacing(0, 3),

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      [`& .${classes.header}`]: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing(3),
      },
      [`& .${classes.percent}`]: {
        fontSize: 88,
      },
    },
    [breakpoints.down('md')]: {
      [`& .${classes.avatarWrapper}`]: {
        display: 'none',
        visibility: 'hidden',
      },
    },
    [breakpoints.up('md')]: {
      [`& .${classes.container}`]: {
        padding: spacing(0, 5),
        justifyContent: 'space-between',
      },

      [`& .${classes.avatarWrapper}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',

        '& > div': {
          border: '4px solid white',
        },
        '& > div + div': {
          marginRight: -46,
        },
      },
    },
  }),
);

function LandingPageGetBetterGrades({ compressed }) {
  return (
    <Root compressed={compressed}>
      <div className={classes.container}>
        <div>
          <Typography aria-level="2" className={classes.header} color="white" component="div" role="heading">
            <Typography className={classes.percent} component="span" variant="h1">
              98%
            </Typography>
            <div>
              <Typography component="span" typography={{ xs: 'body1', sm: 'body3' }}>
                Of students who study with Wizeprep
              </Typography>
              <Typography
                component="span"
                typography={{
                  xs: compressed ? 'subtitle1' : 'h5',
                  sm: 'h5',
                  [compressed ? 'md' : 'sm']: 'h3',
                }}
              >
                get better grades
              </Typography>
            </div>
          </Typography>
        </div>
        <div className={classes.avatarWrapper}>
          {getBetterGradesStudents.map(student => (
            <Avatar key={student.author} alt={student.author} src={student.imageSrc} sx={{ width: 100, height: 100 }} />
          ))}
        </div>
      </div>
    </Root>
  );
}

export default LandingPageGetBetterGrades;
